.App {
  text-align: center;
  padding: 20px 20px 0 20px;
}

.text-left {
  padding-left: 16px;
  text-align: left;
}

.watermark {
  position: relative;
}

.watermark:after {
  content: '';
  display: block;
  pointer-events: none;
  width: 210px;
  height: 150px;
  position: absolute;
  top: 0px;
  left: 50px;
  background-image: url('https://www.berylls.com/wp-content/uploads/2019/10/logo_moblie_extralightgrey_x1-1.png');
  background-size: auto;
  background-position: 30px 30px;
  background-repeat: no-repeat;
  opacity: 0.1;
}

#root {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.rich-text-input-tis .ql-container.ql-snow {
  margin-top: 3px;
  padding: 5px;
  border-radius: 8px;
  border: none;
  background: #fff;
}
