@font-face {
  font-family: 'NeueFaktum-Light';
  font-weight: 300;
  src: url('../assets/fonts/NeueFaktum-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeueFaktum-Regular';
  font-weight: 400;
  src: url('../assets/fonts/NeueFaktum-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeueFaktum-Medium';
  font-weight: 500;
  src: url('../assets/fonts/NeueFaktum-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeueFaktum-SemiBold';
  font-weight: 600;
  src: url('../assets/fonts/NeueFaktum-SemiBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'NeueFaktum-Bold';
  font-weight: 700;
  src: url('../assets/fonts/NeueFaktum-Bold.woff2') format('woff2');
  font-display: swap;
}
